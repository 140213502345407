import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { stocksData } from '../../assets/stockData';

import "./SearchModal.css"

const SearchModal = ({ showSearchModal, setShowSearchModal, setSelectedStock, setShowPPmodal }) => {
    const [inputText, setInputText] = useState("");
    const [isSearching, setIsSearching] = useState(false);

    const nav = useNavigate()

    // Debounce the search text for filtering
    const debouncedSearchText = useDebounce(inputText, 300, setIsSearching);

    // Memoize filtered results based on debounced search text
    const filteredStocks = useMemo(() => {
        if (!debouncedSearchText) return [];

        return Object.values(stocksData).filter(stock =>
            stock.name.toLowerCase().includes(debouncedSearchText.toLowerCase()) ||
            stock.symbol.toLowerCase().includes(debouncedSearchText.toLowerCase())
        );
    }, [debouncedSearchText, isSearching]); // Add isSearching to dependencies


    const handleInputChange = (e) => {
        setInputText(e.target.value);
        // setShowSearchModal(true);
    };

    return (
        <div>

            <Modal
                show={showSearchModal}
                onHide={() => {
                    setInputText(""); // reset input
                    setShowSearchModal(false);                  
                }}
            >
                <div className="search-modal-container">
                    <div className="relative w-full sm-input-container">
                        <input
                            className='search-modal-input'
                            value={inputText}
                            onChange={handleInputChange}
                            placeholder="🔍 Search for a stock..."
                            autoFocus
                            
                        />
                    </div>

                    {isSearching ? (
                        <div className="stock-item-container">
                            <p>Searching... 
                            <div class="loader"></div>
                            </p>
                        </div>
                    ) : filteredStocks.length > 0 ? (
                        filteredStocks.map((stock) => (
                            <div key={stock.symbol} className="stock-item-container"
                                onClick={() => {
                                    setSelectedStock(stock);
                                    setShowPPmodal(true);
                                    setShowSearchModal(false);
                                }}
                            >
                                <p className='stock-item-symbol'>{stock.symbol}</p>
                                <p
                                    onClick={() => {
                                        nav(`/stock/${stock.symbol}`);
                                        setShowSearchModal(false);
                                        setInputText("");
                                    }}
                                    className="stock-item-desc"
                                >
                                    {stock.name} {". "}
                                    {stock.industry}
                                </p>
                            </div>
                        ))
                    ) : inputText ? (
                        <div className="stock-item-container">
                            <p>No results found</p>
                        </div>
                    ) : null}
                </div>
            </Modal>

        </div>
    )
}

export default SearchModal

// Custom hook for debouncing
function useDebounce(value, delay = 300, setIsSearching) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        setIsSearching(true); // Set searching to true when the timer starts
        const handler = setTimeout(() => {
            setDebouncedValue(value);
            setIsSearching(false); // Set searching to false when the debounced value is updated
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay, setIsSearching]);

    return debouncedValue;
}