import React, { useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { sendRequest } from '../../api/be-client';
import './pp.css';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';


const reportTexts = {
    ai: {
        title: "AI-Powered Analysis Report",
        description: "Your AI-Powered Stock Analysis is Ready to Begin",
        intro: "Thank you for choosing BUYORSELL to enhance your investment decisions with cutting-edge AI technology!",
        details: [
            "AI-Generated Market Predictions – Machine-learning models forecasting price trends.",
            "Real-Time Data Insights – Analyzing market trends, news, and technical indicators.",
            "Risk & Volatility Assessment – AI-driven evaluation of price fluctuations and liquidity trends.",
            "Sentiment Analysis – Assessing news and social media impact on stock movements.",
            "Automated Buy/Sell Signals – AI-detected trading opportunities.",
            "Comparison to Traditional Analysis – AI vs. expert-driven technical & fundamental insights.",
            "Clear, Data-Driven Report – Presented in an easy-to-read format."
        ],
        price: "Complete your payment of $19.90 and receive your comprehensive fundamental analysis within 1 hour!",
        conclusion: "💡 Thank you for trusting BUYORSELL. We are committed to delivering cutting-edge insights powered by AI."
    },
    fundamental: {
        title: "Fundamental Analysis Report",
        description: "Your Fundamental Analysis is Ready to Begin",
        intro: "Thank you for choosing BUYORSELL for your fundamental stock analysis!",
        details: [
            "Company Financial Health – Key insights from income statements, balance sheets, and cash flow.",
            "Revenue & Profitability Trends – Analysis of earnings growth, profit margins, and financial sustainability.",
            "Valuation Metrics – P/E, P/B ratios, and industry benchmark comparisons.",
            "Debt & Risk Assessment – Review of debt levels, liabilities, and financial stability.",
            "Industry & Competitor Analysis – Company positioning vs. key competitors.",
            "Growth Potential & Outlook – Forecasts based on business performance and economic factors.",
            "Clear, Actionable Report – Well-structured insights for investors."
        ],
        price: "Complete your payment of $19.90 and receive your comprehensive fundamental analysis within 1 hour!",
        conclusion: "💡 We appreciate your trust and look forward to helping you make informed investment decisions."
    },
    technical: {
        title: "Technical Analysis Report",
        description: "Your Technical Analysis is Ready to Begin",
        intro: "Thank you for choosing BUYORSELL for your technical stock analysis!",
        details: [
            "Market Trends & Key Levels – Identify bullish/bearish trends, support & resistance levels, and market sentiment.",
            "Chart Patterns – Recognizing formations like head & shoulders, double tops/bottoms, and triangles.",
            "Candlestick Insights – Understanding price movements and reversal signals.",
            "Technical Indicators – Analysis of EMA, SMA, RSI, MACD, and Bollinger Bands.",
            "Entry & Exit Points – Suggested buy/sell zones based on price action.",
            "Clear, Actionable Report – Structured insights for easy decision-making."
        ],
        price: "Complete your payment of $19.90 and receive your comprehensive fundamental analysis within 1 hour!",
        conclusion: "💡 We appreciate your trust and look forward to providing valuable insights to guide your investment decisions."
    }
};


export const Checkout = ({ selectedStock, handleCloseModal, arType }) => {
    const [{ isPending }] = usePayPalScriptReducer();
    const [showIntro, setShowIntro] = useState(true)

    const nav = useNavigate()
    const onCreateOrder = (data, actions) => {
        setShowIntro(false)


        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "19.9",
                    },
                },
            ],
        });
    };

    const onApproveOrder = (data, actions) => {
        setShowIntro(true);
        return actions.order.capture().then(async (details) => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);
            const res = await sendRequest(selectedStock.symbol);
            if (res) {
                toast.success("Done");
                handleCloseModal();
                nav("/post-payment");
            } else {
                alert("Error 995. please retry latter");
            }
        });
    };

    const { title, description, intro, details, price, conclusion } = reportTexts[arType];

    return (
        <div className="checkout">
            {isPending ? <p className="loading-text">LOADING...</p> : (
                <div className="paypal-buttons-container">
                    {showIntro && <div>
                        <h3 className='co-prime-title'> {title} For:  {selectedStock.symbol}</h3>
                        {/* <h5 className='co-second-title'> For:  { selectedStock.symbol}</h5> */}
                        <p> {description} </p>
                        <p> {intro} </p>
                        <p>
                            One of our expert analysts is preparing your detailed Analysis Report, which will be sent to your email within 1 hour after payment confirmation.
                        </p>
                        <ul>

                            {details.map((item) => {
                                return <li>{item}</li>
                            })}
                        </ul>
                        <p> {price} </p>
                        <p> {conclusion} </p>

                    </div>}
                    <h4 className='co-second-title'> {selectedStock.symbol}</h4>
                    <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={(data, actions) => onCreateOrder(data, actions)}
                        onApprove={(data, actions) => onApproveOrder(data, actions)}
                    />
                </div>
            )}
        </div>
    );
};
