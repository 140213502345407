import React from 'react';
import './Ai.css';
import image3 from "../../assets/ai-image1.png";
import image5 from "../../assets/ai-image2.png";
import Advantages from './../about/Advantages/Advantages';

const Ai = () => {

    return (
        <div className="ai-container">

            <div className="ai-title-image-container">
                <h1 className='ai-1-text'>AI ANALYSIS</h1>
            </div>


            <div className='ai-3-container'>
                <img className='ai-3-img' src={image3} alt="" />

                <div className='ai-3-right only-pc'>
                    <p className='ai-inner-title'> Tomorrow’s Decision-Making, Today
                    </p>
                    <p className='ai-3-txt'>
                        At our company, alongside our team of experienced technical and analytical experts, we are proud to introduce the next generation of stock analysis – Artificial Intelligence (AI).
                        Integrating AI into our analysis process allows us to deliver advanced, precise, and faster insights based on vast amounts of data and sophisticated algorithms.

                    </p>
                </div>
            </div>


            <div className='only-mobile'>
                <p className='ai-inner-title'> Tomorrow’s Decision-Making, Today </p>
                <p className='ai-3-txt' style={{ maxWidth: '90%', margin: '8% 4%' }}>
                    At our company, alongside our team of experienced technical and analytical experts, we are proud to introduce the next generation of stock analysis – Artificial Intelligence (AI).
                    Integrating AI into our analysis process allows us to deliver advanced, precise, and faster insights based on vast amounts of data and sophisticated algorithms.
                </p>
            </div>


            <div className='ai-5-container'>
                <div>
                    <p className='ai-inner-title'> How Does It Work? </p>
                    <p className='ai-5-inner-text'>
                        Our AI technology collects and analyzes information from a variety of sources – market movements, financial reports, price trends, economic news, and even market sentiment expressed on social media and financial forums.
                        <br />
                        <br />
                        These advanced algorithms identify patterns and trends that are not easily visible to the human eye, delivering forecasts based on deep learning and market expertise.
                        <br />
                    </p>
                </div>
                <img className='ai-3-img only-pc' src={image5} alt="" />
            </div>

            <div className='ai-4-container'>
                <Advantages contentType="ai" />
            </div>

            <div className='ai-6-container'>
                <p className='ai-6-text'>
                    Choosing our AI-powered stock analysis provides you with a significant advantage in the investment landscape.
                    <br/>
                    <br/>
                        By combining cutting-edge technology, the expertise of our analysts, and the ability to adapt to market dynamics, we offer high-quality insights tailored to your specific needs.
                        <br/>
                        <br/>
                        Our approach ensures a balanced, data-rich perspective that empowers better, more confident investment decisions.
                </p>
            </div>
            <br />
        </div>
    );
};

export default Ai;