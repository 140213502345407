import React from 'react'
import image1 from "../../../assets/adv1Image.png";
import image2 from "../../../assets/adv2Image.png";
import image3 from "../../../assets/adv3Image.png";
import image4 from "../../../assets/adv4Image.png";

import aboutImage1 from "../../../assets/adv1Image.png";
import aboutImage2 from "../../../assets/adv2Image.png";
import aboutImage3 from "../../../assets/adv3Image.png";
import aboutImage4 from "../../../assets/adv4Image.png";

import aiImage1 from "../../../assets/adv1Image.png";
import aiImage2 from "../../../assets/ai-benefits-image-1.png";
import aiImage3 from "../../../assets/ai-benefits-image-2.png";
import aiImage4 from "../../../assets/ai-benefits-image-3.png";




import './Advantages.css'

const contentData = {
    about: [
        { title: "Save Time", content: "Receive detailed analysis reports within single 1 hour, enabling you to make timely and well-informed decisions without unnecessary delays", image: aboutImage1 },
        { title: "Qualified analysis", content: "Work with analysts who have over 10 years of expertise in the stock market and cryptocurrency trading. Every report is based on accurate and reliable insights", image: aboutImage2 },
        { title: "Tailored Insights", content: "Each report is customized to address your specific needs, whether you’re trading stocks or exploring cryptocurrency opportunities", image: aboutImage3 },
        { title: "Actionable Recommendations", content: "Gain clear, concise insights designed to support your next step. Our recommendations not only inform but also guide your investment strategies, helping you navigate the market with clarity and confidence", image: aboutImage4 }
    ],
    ai: [
        { title: "Real-Time Insights", content: "Our technology processes massive amounts of data in real-time, giving you immediate and actionable information.", image: aiImage1 },
        { title: "High Accuracy", content: "Our models continually learn and improve, offering highly reliable data-driven forecasts.", image: aiImage2 },
        { title: "Discover Hidden Opportunities", content: "AI identifies patterns and investment prospects that might be missed in traditional analyses", image: aiImage3 },
        { title: "Synergy of Technology and Expertise", content: "Insights are carefully validated by our team of specialists, ensuring a balance between technological precision and professional judgment.", image: aiImage4 }
    ]
};

const Advantages = ({ contentType }) => {
    return (
        <div className='adv-container'>
            {contentType === "about" && <h3 className='adv-title'>Our Advantages</h3>}
            {
                contentType === "about" ?
                    <p className='adv-text'>At BUYORSELL, we aim to give traders the edge they need in a<br />fast-paced market. Here’s what sets us apart:</p>
                    :
                    <p className='adv-text'>Benefits of AI-Based Stock Analysis</p>
            }
            <div className='adv-inner-container'>
                {contentData[contentType]?.map((item, index) => (
                    <div className='adv' key={index}>
                        <img src={item.image} alt={item.title} />
                        <h4 className='adv-inner-title'>{item.title}</h4>
                        <p className='adv-inner-text'>{item.content}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Advantages; 