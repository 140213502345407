import React, { useState } from 'react'
import "./Faq.css"

const FaqElement = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    return <div className='faq-element-container'>
        <h5 onClick={() => { setIsOpen(!isOpen) }}>{title} </h5>
        {isOpen && <p> {content} </p>}
    </div>
}

const Faq = () => {
    return <div className="faq-container">

        <div className="faq-title-image-container">
            <h1 className='faq-1-text'>FAQ</h1>
        </div>
        <div className='faq-content-container'>
        <div className='faq-title'> Everything you need to know <br/>about BUYORSELL</div>
        <div className='faq-sub-title'> Find quick answers to common questions about BUYORSELL and make the <br/>most of our platform. Need more help? Contact us anytime! </div>

        <div className='faq-inner-container'>
                <FaqElement title='What is BUYORSELL?'
                    content='BUYORSELL is a platform that connects traders with professional technical and fundamental analysts. We provide tailored analyses for stocks and cryptocurrencies, delivering clear and accurate insights to help you make informed decisions in the financial markets. In addition to expert analysis, we also incorporate advanced AI-driven analysis, utilizing machine learning algorithms to identify trends and patterns, offering another powerful tool to enhance your trading strategies..' />
                <FaqElement title='How do I sign up for an account?'
                    content="Click the 'Sign Up' button on the homepage and follow the simple steps. You can register using your email or link your Google account for quick access" />
                <FaqElement title='What does the report include?'
                    content="Our reports offer a comprehensive (technical/fundamental) analysis of your chosen asset. If you choose technical analysis, our analysts assess market trends, identify critical points for decision-making, and present conclusions based on proven methodologies. Alternatively, if you decide on fundamental analysis, we provide insights into the asset's underlying economic factors, offering a deeper understanding of its long-term potential. Additionally, we utilize AI-powered tools that analyze vast amounts of market data, identifying patterns and forecasting potential outcomes to complement the insights from human experts. This combination of traditional analysis and AI innovation ensures that every report provides a well-rounded perspective to support your trading decisions." />
                <FaqElement title='Is the report personalized?'
                    content="Yes, each report is customized to the asset you select and includes a detailed analysis tailored to your specific needs, whether for short-term trading or long-term investing. Our analysts focus on the method you choose, ensuring the report aligns with your investment strategy and objective" />
                <FaqElement title='How fast the analysis will be ready once I asked for it?'
                    content="The analysis will be ready in 60 minutes after the request will be sent" />
                <FaqElement title='What makes BUYORSELL different from other services?' 
                    content="At BUYORSELL, our analysts bring over 10 years of experience in stock market and cryptocurrency trading. We are dedicated to delivering high-quality, professional reports quickly and efficiently. What sets us apart is our unique approach, offering three distinct methods of analysis: traditional technical analysis, fundamental analysis, and AI-driven analysis. Each method is presented in a straightforward, easy-to-understand format, tailored to your needs. Whether you choose expert insights or AI-powered predictions, we ensure that you receive clear, actionable information to help you make informed trading decision" />
                <FaqElement title='How long does it take to receive the report?'
                    content='Your report will be sent to you within 12 hours of submitting your request and completing payment.' />
                <FaqElement title='Can I see a sample report before purchasing?'
                    content="Yes, if you’d like to see a sample report, please contact us via the Contact Us page on our website. We’ll be happy to provide you with an example (with anonymized details) to help you understand the quality and structure of our analyses." />
                <FaqElement title='Is this service suitable for beginners?'
                    content="Yes, our service is designed to cater to both beginners and experienced investors. The reports provide clear and concise information for those new to investing, while offering the depth and analysis required by seasoned professionals. This ensures value for users at all levels of expertise" />
                <FaqElement title='How is payment processed?'
                    content="Payments are securely processed through PayPal or credit card, ensuring your personal information is fully protected." />
                
                <FaqElement title='What happens if I don’t receive my report on time?'
                    content="In the unlikely event of a delay, you will be notified immediately, and our support team will ensure you receive your report as quickly as possible." />
                <FaqElement title='What if I’m not satisfied with the report?'
                    content="We are committed to customer satisfaction. If you have any concerns or are not satisfied with the report, please contact us, and we’ll do everything we can to assist you." />

                <FaqElement title='Can I change or cancel my request after payment?'
                    content="Yes, you can modify or cancel your request as long as the analysis has not yet begun. In such cases, we’ll issue a refund or update the request as needed."/>
                <FaqElement title='Is my information kept secure?'
                    content="Absolutely. We adhere to the highest data security standards, and all personal information and payments are protected using advanced technologies." />
                <FaqElement title='Is the service free? '
                    content="Creating an account is completely free. However, access to our reports and analysis requires a subscription, which is priced affordably to ensure value for money." />
        </div>
        </div>
    </div>
}

export default Faq