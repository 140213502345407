
export async function getCryptoPrices() {
    const CACHE_KEY = 'bos-crypto-prices';
    const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds
    // Check if cached data exists and is still valid
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        const now = new Date().getTime();

        if (now - timestamp < CACHE_DURATION) {
            // If cached data is still valid, return it
            return data;
        }
    }

    // If no valid cached data, fetch new data from the API
    const url = 'https://api.coingecko.com/api/v3/coins/markets';
    const params = new URLSearchParams({
        vs_currency: 'usd',
        order: 'market_cap_desc',
        per_page: 20,
        page: 1
    });

    try {
        const response = await fetch(`${url}?${params}`);
        
        if (response.ok) {
            const data = await response.json();

            // Save the new data to localStorage with a timestamp
            const cacheEntry = {
                timestamp: new Date().getTime(),
                data: data
            };
            localStorage.setItem(CACHE_KEY, JSON.stringify(cacheEntry));

            return data;
        } else {
            return `Error: ${response.status}`;
        }
    } catch (error) {
        return `Error: ${error.message}`;
    }
}



export const cryptoData = {
    BTC: {
        company_name: "Bitcoin",
        description: "Bitcoin is the first decentralized cryptocurrency, created in 2009 by an unknown person or group using the pseudonym Satoshi Nakamoto. It operates on a peer-to-peer network without intermediaries, using blockchain technology to ensure transparency and security.",
        employees: null, // Bitcoin is decentralized and has no central company
        industry: "Cryptocurrency",
        market_cap: "~$600 billion"
    },
    ETH: {
        company_name: "Ethereum",
        description: "Ethereum is a decentralized, open-source blockchain system that features smart contract functionality. It was proposed in 2013 by Vitalik Buterin and launched in 2015. Ethereum is the foundation for many decentralized applications (dApps) and the second-largest cryptocurrency by market cap.",
        employees: 200, // Ethereum Foundation
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$200 billion"
    },
    BNB: {
        company_name: "Binance Coin",
        description: "Binance Coin is the native cryptocurrency of the Binance exchange, one of the largest cryptocurrency exchanges in the world. It was launched in 2017 and is used to pay for transaction fees on the Binance platform and participate in token sales.",
        employees: 3000, // Binance
        industry: "Cryptocurrency Exchange",
        market_cap: "~$40 billion"
    },
    XRP: {
        company_name: "Ripple",
        description: "XRP is the native cryptocurrency of the Ripple network, designed for fast and low-cost international payments. Ripple Labs, the company behind XRP, works with financial institutions to improve cross-border transactions.",
        employees: 500, // Ripple Labs
        industry: "Payments, Blockchain",
        market_cap: "~$30 billion"
    },
    ADA: {
        company_name: "Cardano",
        description: "Cardano is a blockchain platform for smart contracts, founded by Charles Hoskinson, one of the co-founders of Ethereum. It aims to provide a more secure and scalable infrastructure for decentralized applications.",
        employees: 150, // IOHK (Input Output Hong Kong)
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$10 billion"
    },
    DOGE: {
        company_name: "Dogecoin",
        description: "Dogecoin is a peer-to-peer cryptocurrency created in 2013 as a joke based on the popular 'Doge' meme. Despite its origins, it has gained a significant following and is used for tipping and small transactions.",
        employees: null, // Decentralized
        industry: "Cryptocurrency",
        market_cap: "~$8 billion"
    },
    SOL: {
        company_name: "Solana",
        description: "Solana is a high-performance blockchain platform designed for decentralized applications and crypto-currencies. It is known for its fast transaction speeds and low fees, making it a popular choice for developers.",
        employees: 100, // Solana Foundation
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$12 billion"
    },
    TRX: {
        company_name: "TRON",
        description: "TRON is a blockchain-based platform designed to support decentralized applications and content sharing. It was founded by Justin Sun in 2017 and aims to decentralize the internet.",
        employees: 200, // TRON Foundation
        industry: "Blockchain, Entertainment",
        market_cap: "~$7 billion"
    },
    LTC: {
        company_name: "Litecoin",
        description: "Litecoin is a peer-to-peer cryptocurrency created by Charlie Lee in 2011. It is often referred to as the 'silver to Bitcoin's gold' and is known for its faster transaction times and lower fees.",
        employees: null, // Decentralized
        industry: "Cryptocurrency",
        market_cap: "~$5 billion"
    },
    DOT: {
        company_name: "Polkadot",
        description: "Polkadot is a multi-chain blockchain platform that enables different blockchains to interoperate and share information. It was founded by Gavin Wood, one of the co-founders of Ethereum.",
        employees: 150, // Web3 Foundation
        industry: "Blockchain, Interoperability",
        market_cap: "~$8 billion"
    },
    MATIC: {
        company_name: "Polygon",
        description: "Polygon (formerly Matic Network) is a layer-2 scaling solution for Ethereum, designed to provide faster and cheaper transactions. It aims to improve the scalability and usability of Ethereum-based applications.",
        employees: 300, // Polygon Labs
        industry: "Blockchain, Scaling Solutions",
        market_cap: "~$6 billion"
    },
    AVAX: {
        company_name: "Avalanche",
        description: "Avalanche is a blockchain platform designed for decentralized applications and custom blockchain networks. It is known for its high throughput and low latency, making it a competitor to Ethereum.",
        employees: 200, // Ava Labs
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$5 billion"
    },
    UNI: {
        company_name: "Uniswap",
        description: "Uniswap is a decentralized exchange (DEX) built on the Ethereum blockchain. It allows users to trade cryptocurrencies without intermediaries, using an automated market-making (AMM) system.",
        employees: 50, // Uniswap Labs
        industry: "Decentralized Finance (DeFi)",
        market_cap: "~$4 billion"
    },
    LINK: {
        company_name: "Chainlink",
        description: "Chainlink is a decentralized oracle network that connects smart contracts with real-world data. It is widely used in decentralized finance (DeFi) applications to provide reliable external data.",
        employees: 100, // Chainlink Labs
        industry: "Blockchain, Oracles",
        market_cap: "~$6 billion"
    },
    XMR: {
        company_name: "Monero",
        description: "Monero is a privacy-focused cryptocurrency that uses advanced cryptographic techniques to ensure anonymous transactions. It is widely used for its strong privacy features.",
        employees: null, // Decentralized
        industry: "Cryptocurrency, Privacy",
        market_cap: "~$3 billion"
    },
    ETC: {
        company_name: "Ethereum Classic",
        description: "Ethereum Classic is a blockchain platform that emerged after a hard fork of Ethereum in 2016. It maintains the original Ethereum blockchain and is focused on immutability and decentralization.",
        employees: null, // Decentralized
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$2 billion"
    },
    ATOM: {
        company_name: "Cosmos",
        description: "Cosmos is a blockchain ecosystem designed to enable interoperability between different blockchains. It uses the Inter-Blockchain Communication (IBC) protocol to connect independent blockchains.",
        employees: 100, // Interchain Foundation
        industry: "Blockchain, Interoperability",
        market_cap: "~$3 billion"
    },
    XLM: {
        company_name: "Stellar",
        description: "Stellar is a blockchain platform designed for fast and low-cost cross-border payments. It was founded by Jed McCaleb and aims to connect financial institutions and individuals.",
        employees: 200, // Stellar Development Foundation
        industry: "Payments, Blockchain",
        market_cap: "~$2.5 billion"
    },
    ICP: {
        company_name: "Internet Computer",
        description: "Internet Computer is a blockchain platform developed by DFINITY that aims to extend the functionality of the internet by hosting decentralized applications and services.",
        employees: 300, // DFINITY Foundation
        industry: "Blockchain, Decentralized Web",
        market_cap: "~$2 billion"
    },
    FIL: {
        company_name: "Filecoin",
        description: "Filecoin is a decentralized storage network that allows users to rent out their unused storage space. It is built on the InterPlanetary File System (IPFS) and aims to create a decentralized storage market.",
        employees: 150, // Protocol Labs
        industry: "Blockchain, Decentralized Storage",
        market_cap: "~$1.5 billion"
    },
    APT: {
        company_name: "Aptos",
        description: "Aptos is a high-performance blockchain platform designed for scalability and security. It was developed by former Meta (Facebook) employees and aims to support decentralized applications.",
        employees: 100, // Aptos Labs
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$1.2 billion"
    },
    HBAR: {
        company_name: "Hedera Hashgraph",
        description: "Hedera Hashgraph is a distributed ledger technology that aims to provide fast and secure transactions. It is governed by a council of organizations and is used for enterprise applications.",
        employees: 200, // Hedera Governing Council
        industry: "Blockchain, Enterprise Solutions",
        market_cap: "~$1.8 billion"
    },
    VET: {
        company_name: "VeChain",
        description: "VeChain is a blockchain platform designed for supply chain management and business processes. It aims to improve transparency and efficiency in various industries.",
        employees: 150, // VeChain Foundation
        industry: "Blockchain, Supply Chain",
        market_cap: "~$1.3 billion"
    },
    NEAR: {
        company_name: "NEAR Protocol",
        description: "NEAR Protocol is a blockchain platform designed for decentralized applications and smart contracts. It focuses on usability and scalability for developers and users.",
        employees: 100, // NEAR Foundation
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$1 billion"
    },
    QNT: {
        company_name: "Quant",
        description: "Quant is a blockchain platform designed to connect different blockchain networks and legacy systems. It uses the Overledger technology to enable interoperability.",
        employees: 50, // Quant Network
        industry: "Blockchain, Interoperability",
        market_cap: "~$1 billion"
    },
    ALGO: {
        company_name: "Algorand",
        description: "Algorand is a blockchain platform designed for scalability and security. It uses a pure proof-of-stake consensus mechanism and aims to support decentralized applications and financial systems.",
        employees: 150, // Algorand Foundation
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$1.2 billion"
    },
    GRT: {
        company_name: "The Graph",
        description: "The Graph is a decentralized indexing protocol for querying blockchain data. It is widely used in decentralized applications to access and organize blockchain data efficiently.",
        employees: 50, // The Graph Foundation
        industry: "Blockchain, Data Indexing",
        market_cap: "~$0.8 billion"
    },
    EGLD: {
        company_name: "Elrond",
        description: "Elrond is a blockchain platform designed for high throughput and low latency. It uses a sharding mechanism to improve scalability and is used for decentralized applications and financial systems.",
        employees: 100, // Elrond Network
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$0.9 billion"
    },
    STX: {
        company_name: "Stacks",
        description: "Stacks is a blockchain platform that brings smart contracts and decentralized applications to Bitcoin. It uses the Bitcoin blockchain for security and enables new use cases for Bitcoin.",
        employees: 50, // Stacks Foundation
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$0.7 billion"
    },
    EOS: {
        company_name: "EOS",
        description: "EOS is a blockchain platform designed for decentralized applications and smart contracts. It aims to provide high scalability and usability for developers and users.",
        employees: 100, // Block.one
        industry: "Blockchain, Smart Contracts",
        market_cap: "~$0.6 billion"
    }
};